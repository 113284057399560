<template>
    <section class="dashboard container-fluid bg-white p-4" style="overflow-y: scroll;">
        <div class="row">
            <div class="col-12 mb-4">
                <span class="ge--description-md">
                    <b>Estudios</b>
                </span>
            </div>

            <div class="col-12">

                <ge-table :data="data_table" :allow_headers="allow_headers">

                    <template v-slot:type="data">
                        <span class="ge--description-xs text-dark">
                            {{ 
                                (data.value == 'apoe') ? 'APO E' : 
                                (data.value == 'pgx') ? 'FARMACOGENÉTICO' : 
                                (data.value == 'genetic') ? 'COMPLETE GENOME' : 
                                'EPIGENÉTICO'
                            }}
                        </span>
                    </template>

                    <template v-slot:creation="data">
                        <span class="ge--description-xs text-dark">
                            {{ $informativeDate(data.value) }}
                        </span>
                    </template>

                    <template v-slot:download="data">
                        <button @click="selectDownload(data.row)"
                            class="btn btn-outline-light btn-pill">

                            <span class="ge--description-xs text-dark">
                                <img :src="ico_download">
                                Descargar    
                            </span>
                        </button>
                    </template>

                </ge-table>

            </div>
        </div>
    </section>
</template>

<script setup>
    import JWT from 'LIBS/JWT'
    import { ref, reactive, computed, inject, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Images
    const ico_download = ref(require('ASSETS/icons/download'))

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects
    const $sendRequest = inject('$sendRequest')
    const $informativeDate = inject('$informativeDate')

    // Computeds

    // Variables
    const allow_headers = ref([
        { key: 'type', label: "Nombre" }, 
        { key: 'creation', label: "Fecha" }, 
        { key: 'download', label: "Descarga" },
    ])
    const data_table = ref([])

    // Methods
    const viewPatientReports = async(patient_id) => {
        store.dispatch('showLoading')

        let session = await store.dispatch('existSession')
        session = JWT.verify('eac5a137951f', session.session)

        if(session.id == null) {
            store.dispatch('showError', 'Ha ocurrido un error con la sessión')
            store.dispatch('hiddenLoading')
            return
        }

        let query = {
            patient_id: session.id
        }

        let response = await $sendRequest('administrator/order/patient_reports', 'GET', null, query)
        // console.log(response, 'response viewPatientReports')

        if(response.success && response.data) {
            let reports = response.data.reports

            if(reports.apoe !== undefined && reports.apoe !== null) {
                data_table.value.push(reports.apoe)
            }
            if(reports.pgx !== undefined && reports.pgx !== null) {
                data_table.value.push(reports.pgx)
            }
            if(reports.genetic !== undefined && reports.genetic !== null) {
                data_table.value.push(reports.genetic)
            }
            if(reports.epigenetic !== undefined && reports.epigenetic !== null && reports.epigenetic.length > 0) {
                for(let report of reports.epigenetic) {
                    data_table.value.push(report)
                }
            }
            store.dispatch('hiddenLoading')
        }
        else if(!response.success && response.errors) {
            store.dispatch('showError', 'Ha ocurrido un error')
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

    const selectDownload = async(data_row) => {
        if(data_row.type == 'genetic') {
            downloadPDF(data_row)
        }
        else {
            downloadExtraReport(data_row)
        }
    }

    const downloadPDF = async(data_row) => {
        let url = `${CONFIG.server[CONFIG.env].api}/administrator/bulk/resource?patient_id=${data_row.patient_id}&order_id=${data_row.order_id}&type=genetic`
        window.open(url, '_blank');
    }

    const downloadExtraReport = async(data_row) => {
        let url = `${CONFIG.server[CONFIG.env].api}/administrator/bulk/resource?patient_id=${data_row.patient_id}&type=${data_row.type}&report_id=${data_row.id}`

        window.open(url, '_blank');
    }

    // Vue Cyclelife
    viewPatientReports()

</script>

<style>
    .dashboard {
        height: 100%;
        border-radius: 12px;
    }
</style>